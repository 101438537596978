import * as React from 'react';
import { useTheme, css } from '@emotion/react';
import { ShoppingBag } from 'react-feather';
import TransitionLink from 'gatsby-plugin-transition-link';
import { useApp } from '~/provider/AppProvider';
import isBrowser from '~/utils/isBrowser';

type Props = {
  categories: string[];
  categoriesSectionRefList: React.Ref<Array<React.Ref<HTMLDivElement>>> | null;
};

function BottomBar({ categories, categoriesSectionRefList }: Props) {
  const theme = useTheme();
  const { footerVisible } = useApp();
  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [showBottomBar, setShowBottomBar] = React.useState(false);
  const bottomBarItemsRefList = React.useRef(
    categories.map((_) => React.useRef())
  );
  const bottomBarRef = React.useRef();
  const bottomBarScrollRef = React.useRef();
  const [bottomBarHeight, setBottomBarHeight] = React.useState(200);

  React.useLayoutEffect(() => {
    setBottomBarHeight(bottomBarRef.current.offsetHeight);
  }, []);

  const handleScroll = React.useCallback(() => {
    const scrollPosition = isBrowser ? window.scrollY + 24 : 0;
    const sectionTopOffsets = categoriesSectionRefList.current.map(
      (sectionRef) => sectionRef.current?.offsetTop
    );

    setShowBottomBar(!footerVisible && scrollPosition >= sectionTopOffsets[0]);

    const currentSection = sectionTopOffsets.reduce(
      (acc, cur, idx) => (scrollPosition >= cur ? idx : acc),
      0
    );

    if (selectCategory !== currentSection) {
      selectCategory(currentSection);
    }
  }, [selectCategory, footerVisible]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  function selectCategory(idx, isClick) {
    bottomBarScrollRef.current?.scroll({
      left: (bottomBarItemsRefList.current[idx]?.current?.offsetLeft || 0) - 16,
      behavior: 'smooth',
    });

    if (isClick && isBrowser) {
      window.scrollTo({
        top:
          categoriesSectionRefList.current[idx]?.current?.offsetTop - 24 || 0,
        behavior: 'smooth',
      });
    }
    setSelectedCategory(idx);
  }

  return (
    <div
      css={{
        position: 'fixed',
        zIndex: theme.zIndex.bottomBar,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
      }}
      ref={bottomBarRef}
    >
      <div
        css={{
          width: '100%',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(5),
          display: 'flex',
          backgroundColor: theme.colors.green,
          borderTop: `2px solid ${theme.colors.sand}`,
          transform: `translateY(${showBottomBar ? 0 : bottomBarHeight}px)`,
          transition: '0.3s',

          [theme.breakpoints.up('sm')]: {
            width: 'inherit',
            padding: theme.spacing(1, 1),
            backgroundColor: theme.colors.green,
            border: `1px solid ${theme.colors.sand}`,
            marginBottom: 16,
            borderRadius: 1000,
          },
        }}
      >
        <div
          ref={bottomBarScrollRef}
          css={{
            display: 'flex',
            overflow: 'scroll',
            scrollPaddingRight: theme.spacing(3),
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {categories.map((category, idx) => {
            return (
              <button
                ref={bottomBarItemsRefList.current[idx]}
                css={{
                  marginLeft: theme.spacing(1),
                  '&:first-of-type': {
                    marginLeft: theme.spacing(2),
                  },
                  '&:last-of-type': {
                    marginRight: theme.spacing(2),
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 44,
                  cursor: 'pointer',
                  padding: theme.spacing(1, 2),
                  borderRadius: '1000px',
                  transition: '0.3s',
                  backgroundColor:
                    idx === selectedCategory
                      ? theme.colors.sand
                      : theme.colors.green,
                  color:
                    idx === selectedCategory
                      ? theme.colors.green
                      : theme.colors.sand,
                  border: 'none',
                  font: 'inherit',
                  outline: 'inherit',
                  textTransform: 'uppercase',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => selectCategory(idx, true)}
              >
                {category}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BottomBar;
