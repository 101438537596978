import { MainContainer } from '~/components';
import * as React from 'react';
import { useTheme, css } from '@emotion/react';
import { BottomBar, CategoryList } from '../components/mobile';
import { Logo } from '~/components';
import { useStore } from '../provider/StoreProvider';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { SEO } from '../components';

const CATEGORIES = ['SAISON', 'DRINKS', 'SHOTS', 'WEIN', 'BIER', 'ZUBEHÖR'];

export default function Menuecharte() {
  const theme = useTheme();
  const { store } = useStore();
  const { collections } = store;
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  const collectionsList = React.useMemo(
    () => collections.filter((cat) => cat.products.length > 0),
    [collections]
  );
  const collectionTitlesList = React.useMemo(
    () => collectionsList.map((col) => col.title),
    [collectionsList]
  );

  const sectionRefList = React.useRef(
    collectionsList.map((_) => React.useRef())
  );

  return (
    <>
      <MainContainer>
        <SEO
          title="Menücharte"
          description="Uf euser Menücharte findsch alles wo für en feuchtfröhliche Abig bruchsch. Drink-Packages, fertig gmixti Cocktails oder Wii und Bier."
        />
        <h1
          css={{
            color: theme.colors.rose,
            textTransform: 'uppercase',
            textAlign: 'center',
            // paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(7),
          }}
        >
          Menücharte
        </h1>
        {collectionsList.map((category, idx) => {
          return (
            <CategoryList
              ref={sectionRefList.current[idx]}
              category={category}
              key={category}
            />
          );
        })}

        <BottomBar
          categories={collectionTitlesList}
          categoriesSectionRefList={sectionRefList}
        />
      </MainContainer>
    </>
  );
}
