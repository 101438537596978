import * as React from 'react';
import { useTheme } from '@emotion/react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { formatPrice } from '../../utils/stringHelper';
import ProductListItem from './ProductListItem';
import useIntersection from '~/hooks/useIntersection';

const CategoryList = React.forwardRef(({ category }, ref) => {
  const { products, title, description } = category;
  const theme = useTheme();
  const titleRef = React.useRef();
  const contentRef = React.useRef();

  return (
    <section
      ref={ref}
      css={{
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        '&:first-of-type': {
          paddingTop: theme.spacing(0),
        },
      }}
    >
      <div
        ref={titleRef}
        css={{
          marginTop: theme.spacing(5),
          fontSize: '1.5rem',
          lineHeight: '3rem',
          color: theme.colors.sand,
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          overflow: 'hidden',
        }}
      >
        <div>{title}</div>
      </div>
      {description && (
        <div
          css={{
            marginTop: theme.spacing(1),
            fontSize: '0.9rem',
            padding: theme.spacing(0, 4),
            color: theme.colors.olive,
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          <div>{description}</div>
        </div>
      )}

      <div
        css={{
          marginTop: theme.spacing(4),
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
          gridGap: theme.spacing(2),
        }}
      >
        {products.map((product, index) => (
          <ProductListItem key={product.id} {...{ category, product }} />
        ))}
      </div>
    </section>
  );
});

export default CategoryList;
