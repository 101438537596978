import * as React from 'react';
import { useTheme } from '@emotion/react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { formatPrice } from '../../utils/stringHelper';
import useIntersection from '../../hooks/useIntersection';
import Link from '../Link';
import ProductLabel from './ProductLabel';
import { FaUmbrellaBeach } from 'react-icons/fa';
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link';

function ProductListItem({ product, category }) {
  const theme = useTheme();
  const ref = React.useRef();
  const [, hasIntersected] = useIntersection(ref, { threshold: 0.2 });
  const { handle, images, metafields, title, tags, variants } = product;

  const minPrice = React.useMemo(
    () =>
      variants.reduce(
        (acc, cur) => (acc && acc < cur.price ? acc : cur.price),
        null
      ),
    [variants]
  );

  const hasMultiplePrices = React.useMemo(
    () =>
      variants.reduce(
        (acc, cur, idx) =>
          variants[idx].price !== variants[Math.max(0, idx - 1)].price,
        false
      ),
    [variants]
  );

  return (
    <Link
      css={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      to={`/${category.handle}/${handle}`}
    >
      <div
        ref={ref}
        key={handle}
        css={{
          paddingBottom: theme.spacing(4),
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          opacity: hasIntersected ? 1 : 0,
          transform: `translateY(${hasIntersected ? 0 : 60}px)`,
          transition: '0.6s ease',
        }}
      >
        <div>
          <GatsbyImage
            image={images[0].localFile.childImageSharp.gatsbyImageData}
            alt={title}
            style={{ width: '100%', height: 'auto' }}
            objectFit="cover"
          />
          <div
            css={{
              position: 'absolute',
              top: theme.spacing(2),
              left: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <ProductLabel
              size="small"
              productLabel={
                metafields.find((mf) => mf.key === 'labels')?.value?.[0]
              }
              shrink
              color="sand"
            />
          </div>
        </div>
        <div
          css={{
            alignSelf: 'center',
            borderBottom:
              tags?.length > 0 ? `2px solid ${theme.colors.sand}` : 'inherit',
          }}
        >
          <h4
            css={{
              marginBlockEnd: '0.6rem',
              color: theme.colors.sand,
            }}
          >
            {title}
          </h4>
        </div>
        {tags?.length > 0 && (
          <p
            css={{
              marginBlockStart: '1.5rem',
              marginBlockEnd: '0.6rem',
            }}
          >
            {tags.join(', ')}
          </p>
        )}
        <p
          css={{
            marginBlockStart: '0.6rem',
            color: theme.colors.sand,
            fontSize: '0.9rem',
          }}
        >
          {`${hasMultiplePrices ? 'ab ' : ''}${formatPrice(minPrice)}`}
        </p>
      </div>
    </Link>
  );
}

export default ProductListItem;
